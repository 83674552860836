import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
import image6 from "../assets/image6.png";
import image7 from "../assets/image7.png";
import image8 from "../assets/image8.png";
import image9 from "../assets/image9.png";
import image10 from "../assets/image10.png";
import image11 from "../assets/image11.png";
import image12 from "../assets/image12.png";
import image13 from "../assets/image13.png";
import image14 from "../assets/image14.png";
import image15 from "../assets/image15.png";
import image16 from "../assets/image16.png";
import image17 from "../assets/image17.png";
import image18 from "../assets/image18.png";

import imageb1 from "../assets/imageb1.jpg";
import imageb2 from "../assets/imageb2.jpg";
import imageb3 from "../assets/imageb3.jpg";
import imageb4 from "../assets/imageb4.jpg";
import imageb5 from "../assets/imageb5.jpg";
import imageb6 from "../assets/imageb6.jpg";
import imageb7 from "../assets/imageb7.jpg";
import imageb8 from "../assets/imageb8.jpg";
import imageb9 from "../assets/imageb9.jpg";
import imageb10 from "../assets/imageb10.jpg";
import imageb11 from "../assets/imageb11.jpg";
import imageb12 from "../assets/imageb12.jpg";
import imageb13 from "../assets/imageb13.jpg";
import imageb14 from "../assets/imageb14.jpg";
import imageb15 from "../assets/imageb15.jpg";
import imageb16 from "../assets/imageb16.jpg";
import imageb17 from "../assets/imageb17.jpg";
import imageb18 from "../assets/imageb18.jpg";
import imageb19 from "../assets/imageb19.jpg";
import imageb20 from "../assets/imageb20.jpg";
import imageb21 from "../assets/imageb21.jpg";
import imageb22 from "../assets/imageb22.jpg";
import imageb23 from "../assets/imageb23.jpg";
import imageb24 from "../assets/imageb24.jpg";
import imageb25 from "../assets/imageb25.jpg";
import imageb26 from "../assets/imageb26.jpg";
import imageb27 from "../assets/imageb27.jpg";
import imageb28 from "../assets/imageb28.jpg";
import imageb29 from "../assets/imageb29.jpg";
import imageb30 from "../assets/imageb30.jpg";
import imageb31 from "../assets/imageb31.jpg";
import imageb32 from "../assets/imageb32.jpg";
import imageb33 from "../assets/imageb33.jpg";
import imageb34 from "../assets/imageb34.jpg";
import imageb35 from "../assets/imageb35.jpg";
import imageb36 from "../assets/imageb36.jpg";

import image_c_1 from "../assets/image_c_1.jpg";
import image_c_2 from "../assets/image_c_2.jpg";
import image_c_3 from "../assets/image_c_3.jpg";
import image_c_4 from "../assets/image_c_4.jpg";
import image_c_5 from "../assets/image_c_5.jpg";
import image_c_6 from "../assets/image_c_6.jpg";
import image_c_7 from "../assets/image_c_7.jpg";
import image_c_8 from "../assets/image_c_8.jpg";
import image_c_9 from "../assets/image_c_9.jpg";
import image_c_10 from "../assets/image_c_10.jpg";
import image_c_11 from "../assets/image_c_11.jpg";
import image_c_12 from "../assets/image_c_12.jpg";
import image_c_13 from "../assets/image_c_13.jpg";
import image_c_14 from "../assets/image_c_14.jpg";
import image_c_15 from "../assets/image_c_15.jpg";
import image_c_16 from "../assets/image_c_16.jpg";
import image_c_17 from "../assets/image_c_17.jpg";
import image_c_18 from "../assets/image_c_18.jpg";
import image_c_19 from "../assets/image_c_19.jpg";
import image_c_20 from "../assets/image_c_20.jpg";
import image_c_21 from "../assets/image_c_21.jpg";
import image_c_22 from "../assets/image_c_22.jpg";
import image_c_23 from "../assets/image_c_23.jpg";
import image_c_24 from "../assets/image_c_24.jpg";

import image_d_1 from "../assets/image_d_1.jpg";
import image_d_2 from "../assets/image_d_2.jpg";
import image_d_3 from "../assets/image_d_3.jpg";
import image_d_4 from "../assets/image_d_4.jpg";
import image_d_5 from "../assets/image_d_5.jpg";
import image_d_6 from "../assets/image_d_6.jpg";
import image_d_7 from "../assets/image_d_7.jpg";
import image_d_8 from "../assets/image_d_8.jpg";
import image_d_9 from "../assets/image_d_9.jpg";
import image_d_10 from "../assets/image_d_10.jpg";
import image_d_11 from "../assets/image_d_11.jpg";
import image_d_12 from "../assets/image_d_12.jpg";
import image_d_13 from "../assets/image_d_13.jpg";
import image_d_14 from "../assets/image_d_14.jpg";
import image_d_15 from "../assets/image_d_15.jpg";
import image_d_16 from "../assets/image_d_16.jpg";
import image_d_17 from "../assets/image_d_17.jpg";
import image_d_18 from "../assets/image_d_18.jpg";
import image_d_19 from "../assets/image_d_19.jpg";
import image_d_20 from "../assets/image_d_20.jpg";
import image_d_21 from "../assets/image_d_21.jpg";
import image_d_22 from "../assets/image_d_22.jpg";
import image_d_23 from "../assets/image_d_23.jpg";
import image_d_24 from "../assets/image_d_24.jpg";
import image_d_25 from "../assets/image_d_25.jpg";

const PRODUCT_DATA = [
  {
    index: 1,
    brand: "조던",
    productName: "에어 조던1 로우 OG SP 트레비스 스캇 미디엄 올리브",
    price: 570000,
    images: [image1, image2],
  },
  {
    index: 2,
    brand: "Asics",
    productName: "(W) 아식스 젤 카야노 14 화이트 미드나잇",
    price: 145000,
    images: [image3, image4],
  },
  {
    index: 3,
    brand: "New Balance",
    productName: "뉴발란스 993 메이드 인 USA 그레이 - D 스탠다드",
    price: 307000,
    images: [image5, image6],
  },
  {
    index: 4,
    brand: "Nike",
    productName: "나이키 에어포스 1 '07 로우 화이트",
    price: 100000,
    images: [image7, image8],
  },
  {
    index: 5,
    brand: "Keen",
    productName: "킨 재스퍼 Rocks SP 아이보리 남성 스니커즈",
    price: 189000,
    images: [image9, image10],
  },
  {
    index: 6,
    brand: "ADIDAS",
    productName: "아디다스 삼바 OG 클라우드 화이트",
    price: 104000,
    images: [image11, image12],
  },
  {
    index: 7,
    brand: "NIKE",
    productName: "나이키 덩크 로우 레트로 그레이 포그",
    price: 78000,
    images: [image13, image14],
  },
  {
    index: 8,
    brand: "UGG",
    productName: "(W) 어그 클래식 미니 2 부츠 체스트넛s",
    price: 181000,
    images: [image15, image16],
  },
  {
    index: 9,
    brand: "On Running",
    productName: "(W) 온 러닝 클라우드 5 올 블랙",
    price: 169000,
    images: [image17, image18],
  },
  {
    index: 10,
    brand: "조던",
    productName: "에어 조던1 로우 OG SP 트레비스 스캇 미디엄 올리브",
    price: 570000,
    images: [image1, image2],
  },
  {
    index: 11,
    brand: "Asics",
    productName: "(W) 아식스 젤 카야노 14 화이트 미드나잇",
    price: 145000,
    images: [image3, image4],
  },
  {
    index: 12,
    brand: "New Balance",
    productName: "뉴발란스 993 메이드 인 USA 그레이 - D 스탠다드",
    price: 307000,
    images: [image5, image6],
  },
  {
    index: 13,
    brand: "Nike",
    productName: "나이키 에어포스 1 '07 로우 화이트",
    price: 100000,
    images: [image7, image8],
  },
  {
    index: 14,
    brand: "Keen",
    productName: "킨 재스퍼 Rocks SP 아이보리 남성 스니커즈",
    price: 189000,
    images: [image9, image10],
  },
  {
    index: 15,
    brand: "ADIDAS",
    productName: "아디다스 삼바 OG 클라우드 화이트",
    price: 104000,
    images: [image11, image12],
  },
  {
    index: 16,
    brand: "NIKE",
    productName: "나이키 덩크 로우 레트로 그레이 포그",
    price: 78000,
    images: [image13, image14],
  },
  {
    index: 17,
    brand: "UGG",
    productName: "(W) 어그 클래식 미니 2 부츠 체스트넛s",
    price: 181000,
    images: [image15, image16],
  },
  {
    index: 18,
    brand: "On Running",
    productName: "(W) 온 러닝 클라우드 5 올 블랙",
    price: 169000,
    images: [image17, image18],
  },
];

const PRODUCT_DATA2 = [
  {
    index: 1,
    brand: "가벽",
    productName: "인테리어 가로 와이드파티션 335",
    price: 54900,
    images: [imageb1, imageb2],
  },
  {
    index: 2,
    brand: "가벽",
    productName: "파티션 안방 셀프 설치 원룸 공간분리 간살 가림막파티션 292mm",
    price: 69900,
    images: [imageb3, imageb4],
  },
  {
    index: 3,
    brand: "가벽",
    productName: "파티션 안방 셀프 설치 원룸 공간분리 간살 가림막파티션 292mm",
    price: 307000,
    images: [imageb5, imageb6],
  },
  {
    index: 4,
    brand: "가벽",
    productName: "파티션 셀프 무타공 설치 공간분리 칸막이 가림막파티션 335mm",
    price: 68500,
    images: [imageb7, imageb8],
  },
  {
    index: 5,
    brand: "파티션",
    productName: "밀키 주방 아일랜드 유리 파티션 400",
    price: 40000,
    images: [imageb9, imageb10],
  },
  {
    index: 6,
    brand: "파티션",
    productName: "베르 아치형 라운드 가림막 공간분리 인테리어 파티션 1200",
    price: 78500,
    images: [imageb11, imageb12],
  },
  {
    index: 7,
    brand: "파티션",
    productName: "아치형 미니 우드칸막이 중간 파티션 1200",
    price: 28900,
    images: [imageb13, imageb14],
  },
  {
    index: 8,
    brand: "파티션",
    productName: "접이식 낮은 타공판 칸막이 인테리어 파티션 1200 1단",
    price: 28900,
    images: [imageb15, imageb16],
  },
  {
    index: 9,
    brand: "파티션",
    productName: "클루 아치형 가림막파티션 칸막이 스탠드 파티션",
    price: 59800,
    images: [imageb17, imageb18],
  },
  {
    index: 10,
    brand: "파티션",
    productName: "펀칭 공간분리 인테리어 칸막이 타공 파티션 1200",
    price: 49800,
    images: [imageb19, imageb20],
  },
  {
    index: 11,
    brand: "테이블",
    productName: "루이 침대 폭 좁은 틈새 수납 슬림 수납장 600X480",
    price: 22900,
    images: [imageb21, imageb22],
  },
  {
    index: 12,
    brand: "테이블",
    productName: "메이 비정형 소파 거실테이블 L",
    price: 79900,
    images: [imageb23, imageb24],
  },
  {
    index: 13,
    brand: "테이블",
    productName: "블랑 좌식 소파 거실 테이블 M",
    price: 58800,
    images: [imageb25, imageb26],
  },
  {
    index: 14,
    brand: "테이블",
    productName: "오벌 거실 소파 사이드테이블",
    price: 55800,
    images: [imageb27, imageb28],
  },
  {
    index: 15,
    brand: "테이블",
    productName: "이든 미니테이블 거실 화분 쇼파 협탁 침대 사이드테이블 S",
    price: 69000,
    images: [imageb29, imageb30],
  },
  {
    index: 16,
    brand: "테이블",
    productName: "침대 옆 미니 타공판 협탁",
    price: 70000,
    images: [imageb31, imageb32],
  },
  {
    index: 17,
    brand: "창호",
    productName: "AL 시스템주방창 E9-ATHSH90 LG샷시 아파트 베란다 샷시교체",
    price: 158000,
    images: [imageb33, imageb34],
  },
  {
    index: 18,
    brand: "창호",
    productName: "PVC시스템창 E9-PLS200 LG샷시 아파트 베란다 샷시교체",
    price: 986000,
    images: [imageb34, imageb35],
  },
];

const PRODUCT_DATA3 = [
  {
    index: 1,
    brand: "ASUS",
    productName: "TUF Gaming A14 FA401UV-RG025",
    price: 1647000,
    images: [image_c_1, image_c_2],
  },
  {
    index: 2,
    brand: "레노버",
    productName: "씽크패드 X1 카본 Gen12 21KCCTO1WWKR5 (SSD 256GB)",
    price: 1742000,
    images: [image_c_3, image_c_4],
  },
  {
    index: 3,
    brand: "ASUS",
    productName: "비보북 S 16 OLED S5606MA-MX090 (SSD 1TB)",
    price: 1413000,
    images: [image_c_5, image_c_6],
  },
  {
    index: 4,
    brand: "주연테크",
    productName: "굿워크 D1v56G 블랙 (8GB, M.2 256GB)",
    price: 344000,
    images: [image_c_7],
  },
  {
    index: 5,
    brand: "영웅컴퓨터",
    productName: "영웅 게이밍울트라 7546GT (32GB, M.2 1TB)",
    price: 1069000,
    images: [image_c_8],
  },
  {
    index: 6,
    brand: "마이피씨샵",
    productName: "DIY 게이밍 마스터 7500F RTX4060",
    price: 939000,
    images: [image_c_9],
  },
  {
    index: 7,
    brand: "삼성전자",
    productName: "데스크탑 Tower DM500TGA-A58A",
    price: 937000,
    images: [image_c_10],
  },
  {
    index: 8,
    brand: "영웅컴퓨터",
    productName: "영웅 875 게이밍울트라560X",
    price: 779000,
    images: [image_c_11],
  },
  {
    index: 9,
    brand: "Western",
    productName: "Digital WD RED Pro 7200/512M (WD181KFGX, 18TB)",
    price: 692500,
    images: [image_c_12],
  },
  {
    index: 10,
    brand: "Seagate",
    productName: "IronWolf Pro 7200/512M",
    price: 939500,
    images: [image_c_13],
  },
  {
    index: 11,
    brand: "SK하이닉스",
    productName: "Platinum P41 M.2 NVMe",
    price: 225000,
    images: [image_c_14],
  },
  {
    index: 12,
    brand: "Western",
    productName: "Digital WD BLACK SN850X M.2 NVMe (2TB)",
    price: 203000,
    images: [image_c_15],
  },
  {
    index: 13,
    brand: "G.SKILL",
    productName: "DDR5-6000 CL30 RIPJAWS M5 RGB 화이트 패키지 (32GB(16Gx2))",
    price: 337000,
    images: [image_c_16],
  },
  {
    index: 14,
    brand: "ESSENCORE",
    productName: "KLEVV DDR5-6000 CL30 CRAS V RGB WHITE 패키지 서린",
    price: 177000,
    images: [image_c_17],
  },
  {
    index: 15,
    brand: "인텔",
    productName: "코어 울트라9 시리즈2 285K (애로우레이크) (정품)",
    price: 885000,
    images: [image_c_18],
  },
  {
    index: 16,
    brand: "인텔",
    productName: "코어i7-14세대 14700K (랩터레이크 리프레시)",
    price: 532000,
    images: [image_c_19],
  },
  {
    index: 17,
    brand: "AMD",
    productName: "라이젠5-4세대 5600 (버미어)",
    price: 121000,
    images: [image_c_20],
  },
  {
    index: 18,
    brand: "MSI",
    productName: "MAG B650 토마호크 WIFI",
    price: 458900,
    images: [image_c_21],
  },
  {
    index: 19,
    brand: "MSI",
    productName: "PRO B760M-A DDR4 II",
    price: 138000,
    images: [image_c_22],
  },
  {
    index: 20,
    brand: "MSI",
    productName: "지포스 RTX 4070 Ti SUPER 게이밍 X 슬림 D6X 16GB 트라이프로져3",
    price: 1266000,
    images: [image_c_23],
  },
  {
    index: 21,
    brand: "ASUS",
    productName: "DUAL 라데온 RX 7600 O8G V2 OC D6 8G",
    price: 344000,
    images: [image_c_24],
  },
];

const PRODUCT_DATA4 = [
  {
    index: 1,
    brand: "[포천이동식품]",
    productName: "순두부 400g",
    price: 620,
    images: [image_d_1],
  },
  {
    index: 2,
    brand: "",
    productName: "국내산 샤인머스켓 3수 2kg(내외) 박스",
    price: 12900,
    images: [image_d_2],
  },
  {
    index: 3,
    brand: "",
    productName: "국내산 피양파(특) 15kg 1망",
    price: 22900,
    images: [image_d_3],
  },
  {
    index: 4,
    brand: "",
    productName: "국내산 양배추(특) 9kg 1망(3통)",
    price: 11900,
    images: [image_d_4],
  },
  {
    index: 5,
    brand: "[서신]",
    productName: "참잘자란 대란 1.56kg(52gx30)",
    price: 5980,
    images: [image_d_5],
  },
  {
    index: 6,
    brand: "[다이아몬드]",
    productName: "중국산 냉동 흰다리새우(21/25) 900g",
    price: 22800,
    images: [image_d_6],
  },
  {
    index: 7,
    brand: "",
    productName: "중국산 냉동 오징어(할복) 4kg",
    price: 48900,
    images: [image_d_7],
  },
  {
    index: 8,
    brand: "",
    productName: "중국산 번데기 1kg",
    price: 8980,
    images: [image_d_8],
  },
  {
    index: 9,
    brand: "",
    productName: "중국산 보리굴비 1마리",
    price: 7490,
    images: [image_d_9],
  },
  {
    index: 10,
    brand: "[라폰테]",
    productName: "마카로니 500g",
    price: 1490,
    images: [image_d_10],
  },
  {
    index: 11,
    brand: "라폰테",
    productName: "스파게티면 500g",
    price: 1490,
    images: [image_d_11],
  },
  {
    index: 12,
    brand: "[식자재왕]",
    productName: "쫀득한 밀떡볶이떡(사선) 2kg",
    price: 5990,
    images: [image_d_12],
  },
  {
    index: 13,
    brand: "[바이오션]",
    productName: "청파래 생선까스 3kg",
    price: 23900,
    images: [image_d_13],
  },
  {
    index: 14,
    brand: "[이금기]",
    productName: "중화 해선장(호이신소스) 240g",
    price: 3990,
    images: [image_d_14],
  },
  {
    index: 15,
    brand: "",
    productName: "국내산 땅끝해남 절임배추 (예약판매) 20kg (내외) 박스",
    price: 36900,
    images: [image_d_15],
  },
  {
    index: 16,
    brand: "[해팜]",
    productName: "꼬치어묵(사각) 750g(30gx25)",
    price: 5990,
    images: [image_d_16],
  },
  {
    index: 17,
    brand: "",
    productName: "국내산 새송이버섯(특) 2kg(내외) 박스",
    price: 8990,
    images: [image_d_17],
  },
  {
    index: 18,
    brand: "[이금기]",
    productName: "팬더 굴소스(스파우트팩) 2kg",
    price: 9990,
    images: [image_d_18],
  },
  {
    index: 19,
    brand: "",
    productName: "국내산 알타리 무우 2kg 1단",
    price: 4990,
    images: [image_d_19],
  },
  {
    index: 20,
    brand: "[풀무원]",
    productName: "얇은피꽉찬속 고기만두 640g(320gx2)",
    price: 6990,
    images: [image_d_20],
  },
  {
    index: 21,
    brand: "[풀무원]",
    productName: "얇은피꽉찬속 김치만두 640g(320gx2)",
    price: 6990,
    images: [image_d_21],
  },
  {
    index: 22,
    brand: "[한가네]",
    productName: "새우젓 2kg",
    price: 8800,
    images: [image_d_22],
  },
  {
    index: 23,
    brand: "[해찬들]",
    productName: "가득한 고추장 14kg",
    price: 28900,
    images: [image_d_23],
  },
  {
    index: 24,
    brand: "[해찬들]",
    productName: "알찬쌈장 14kg",
    price: 28900,
    images: [image_d_24],
  },
  {
    index: 25,
    brand: "[해찬들]",
    productName: "가득한 재래식된장 14kg",
    price: 22900,
    images: [image_d_25],
  },
];

function caseInSwitch(val: string | undefined) {
  let answer = PRODUCT_DATA;
  switch (val) {
    case "(주식회사) 대운건설":
      answer = PRODUCT_DATA2;
      break;
  }
  switch (val) {
    case "유한회사 프레테일":
      answer = PRODUCT_DATA3;
      break;
  }
  switch (val) {
    case "(주식회사) 오름길":
      answer = PRODUCT_DATA4;
      break;
  }
  return answer;
}

export default caseInSwitch(process.env.REACT_APP_COMPANYNAME);
